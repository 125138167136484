(function ($) {
  "use strict";
  (function(){
    function id(v){ return document.getElementById(v); }
    function loadbar() {
      var ovrl = id("overlay"),
          prog = id("progress"),
          stat = id("progstat"),
          img = document.images,
          c = 0,
          tot = img.length;
      if(tot == 0) return doneLoading();
  
      function imgLoaded(){
        c += 1;
        var perc = ((100/tot*c) << 0) +"%";
        prog.style.width = perc;
        stat.innerHTML = "Loading "+ perc;
        if(c===tot) return doneLoading();
      }
      function doneLoading(){
        ovrl.style.opacity = 0;
        setTimeout(function(){ 
          ovrl.style.display = "none";
        }, 1200);
      }
      for(var i=0; i<tot; i++) {
        var tImg     = new Image();
        tImg.onload  = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src     = img[i].src;
      }    
    }
    document.addEventListener('DOMContentLoaded', loadbar, false);
  }());

  $(document).ready(function () {
    //Section Slider
    function doAnimations(elements) {
      var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
      elements.each(function () {
        var $this = $(this);
        var $animationDelay = $this.data('delay');
        var $animationType = 'animated ' + $this.data('animation');
        $this.css({
          'animation-delay': $animationDelay,
          '-webkit-animation-delay': $animationDelay
        });
        $this.addClass($animationType).one(animationEndEvents, function () {
          $this.removeClass($animationType);
        });
      });
    }

    $('.section-slider').on('beforeChange', function (e, slick, currentSlide, nextSlide) {
      var $animatingElements = $('.section-slider .slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
      doAnimations($animatingElements);
    });
    $('.section-slider').slick({
      arrows: false,
      pauseOnHover: false,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      infinite: true,
      dots: true,
    });

    // blog slider
    $('.blog-slider').slick({
      pauseOnHover: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      dots: true,
      prevArrow: '<div class="slick-prev"></div>',
      nextArrow: '<div class="slick-next"></div>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })

    //event slider
    $('.event-slider').slick({
      infinite: false,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      slidesPerRow: 4,
      rows: 2,
      prevArrow: '<div class="slick-prev"><i class="fas fa-chevron-left"></i></div>',
      nextArrow: '<div class="slick-next"><i class="fas fa-chevron-right"></i></div>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesPerRow: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesPerRow: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesPerRow: 1,
          }
        }
      ]
    })

    //testimony slider
    $('.testimony-slider').slick({
      infinite: true,
      dots: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      autoplaySpeed: 5000,
      prevArrow: '<div class="slick-prev"></div>',
      nextArrow: '<div class="slick-next"></div>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })

    //Menu Mobile
    $(".burger").on("click", function (e) {
      e.preventDefault();
      if (!$(this).hasClass("open")) {
        openMenu();
      } else {
        closeMenu();
      }
    });

    function openMenu() {
      $(".menu-bg").addClass("animate");
      $(".burger").addClass("open");
      $(".logo a").addClass("open");
      $(".x, .z").addClass("collapse-icon");
      $(".menu-navigation").addClass("animate");
      $("body").css({
        overflow: "hidden",
        height: "100vh"
      });
      // var header = $('.header').outerHeight();
      // console.log(header)
      // $('.menu-navigation').css({
      //   'top': header
      // });
      setTimeout(function () {
        $(".y").hide();
        $(".x").addClass("rotate30");
        $(".z").addClass("rotate150");
      }, 70);
      setTimeout(function () {
        $(".x").addClass("rotate45");
        $(".z").addClass("rotate135");
      }, 120);
    }

    function closeMenu() {
      $(".menu-navigation").removeClass("animate");
      $(".logo a").removeClass("open");
      $("body").css({
        overflow: "",
        height: ""
      });
      // $('.menu-navigation').css({
      //   'top': ''
      // });

      setTimeout(function () {
        $(".burger").removeClass("open");
        $(".x")
          .removeClass("rotate45")
          .addClass("rotate30");
        $(".z")
          .removeClass("rotate135")
          .addClass("rotate150");
        $(".menu-bg").removeClass("animate");

        setTimeout(function () {
          $(".x").removeClass("rotate30");
          $(".z").removeClass("rotate150");
        }, 50);
        setTimeout(function () {
          $(".y").show();
          $(".x, .z").removeClass("collapse-icon");
        }, 70);
      }, 100);
    }

    function headerScroll(target) {
      var target = $(target);
      var targetHeight = target.outerHeight();
      var paddingPercent = (targetHeight - window.scrollY) / targetHeight * 30; // from 1 to 0
      var scrollPercent = window.scrollY / targetHeight; // from 0 to 1
      if (scrollPercent >= 0) {
        target.css('background-color', 'rgba(255, 255, 255,' + scrollPercent + ')');
        target.css('box-shadow', '0px 0px 10px 0px rgba(199,199,199,' + scrollPercent + ')');
        if ($(window).width() > 767) {
          target.css({
            paddingTop: paddingPercent + 'px',
            paddingBottom: paddingPercent + 'px'
          });
          $('.logo').css('font-size', 45 - (scrollPercent * 10 ) )
          $('.logo').css('height', 90 - (scrollPercent * 10 ) )
        } else {
          target.css({
            paddingTop: 0,
            paddingBottom: 0
          });
        }
      }
      if (window.scrollY > targetHeight) {
        if ($(window).width() > 767) {
          $('.logo').css('font-size', 35 )
          $('.logo').css('height', 80 )
        }
        target.css({
          paddingTop: 0,
          paddingBottom: 0
        });
      }
    }
    headerScroll('.homepage .header');
    $(window).on("scroll resize", function (e) {
      headerScroll('.homepage .header');
    });

    //change file .svg to svg inline
    $('img[src$=".svg"]').each(function () {
      var $img = jQuery(this);
      var imgURL = $img.attr('src');
      var attributes = $img.prop("attributes");

      $.get(imgURL, function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find('svg');

        // Remove any invalid XML tags
        $svg = $svg.removeAttr('xmlns:a');

        // Loop through IMG attributes and apply on SVG
        $.each(attributes, function () {
          $svg.attr(this.name, this.value);
        });

        // Replace IMG with SVG
        $img.replaceWith($svg);
      }, 'xml');
    });

    //modal video About

    // Gets the video src from the data-src on each button
    var $videoSrc;
    $(".thumb").on("click", function (e) {
      e.preventDefault();
      $videoSrc = $(this).data("src");
    });


    // when the modal is opened autoplay it
    $("#modalAboutVideo, #modalTestimony, #modalTestimonyDetail").on("shown.bs.modal", function (e) {
      // set the video src to autoplay and not to show related video. 
      // Youtube related video is like a box of chocolates... 
      // you never know what you're gonna get
      $("#video, #videoTestimony").attr(
        "src",
        $videoSrc + "?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1"
      );
    });

    // stop playing the youtube video when I close the modal
    $("#modalAboutVideo, #modalTestimony, #modalTestimonyDetail").on("hide.bs.modal", function (e) {
      // a poor man's stop video
      $("#video, #videoTestimony").attr("src", "");
    });

  });
  // $(window).on('load', function(){
  //     $('.spinner').fadeOut();
  // });
})(jQuery);